<template>
  <div class="main" :class="show ? 'h5main' : ''">
    <mtop />
    <div class="save_head">
      <h1 class="head_title">免费获取方案和报价</h1>
      <div class="head_tip">
        公司主要从事：网站建设/UI设计/APP开发/小程序开发/区块链/网站优化/网站维护等服务
      </div>
      <div class="head_inp">
        <input
          type="text"
          class="inp"
          v-model="umobile"
          autofocus="autofocus"
          placeholder="请输入您的手机号"
          v-focus
        />
        <div class="btn1" @click="getprice">获取方案和报价</div>
      </div>
      <div class="head_bot">
        报价流程：填写手机号-点击获取方案和报价-30秒等待回复
      </div>
    </div>

    <div class="save_box1">
      <h1 class="box1_title">定制开发优势</h1>
      <h2 class="box1_tip">专注于企业网站建设，有效提升品牌形象</h2>
      <div class="box1_bottom">
        <div class="bot_left">
          <!-- dzact -->
          <div
            class="left_item"
            :class="{ dzact: index == i }"
            v-for="(item, i) in banbox"
            :key="i"
          >
            <div class="item_left">
              <img :src="item.l_img" alt="" v-show="index == i" />
              <img :src="item.l_imgs" alt="" v-show="index != i" />
            </div>
            <div class="item_right">
              <div>{{ item.title }}</div>
              <div>{{ item.tip }}</div>
            </div>
          </div>
        </div>
        <div class="bot_right">
          <el-carousel
            height="418px"
            direction="vertical"
            :autoplay="autoplay"
            width="847px"
            @change="changeban"
            :indicator-position="show ? 'none' : ''"
          >
            <el-carousel-item
              v-for="(item, index) in banbox"
              :key="index"
              style="wisth: 847px; height: 418px"
              @mouseover="moveleft(index)"
              @monseout="outleft"
            >
              <img :src="item.img" alt="" />
              <div class="r_t">{{ item.title }}</div>
              <div class="r_d">
                {{ item.description }}
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <div class="save_box2">
      <h1 class="box2_title">参考报价</h1>
      <h2 class="box2_tip">给到客户最实惠的报价，快捷放心</h2>
      <div class="box2_bot">
        <div class="bot_item item1">
          <div class="item_head">
            <div>模板建站</div>
            <div>创业初期，追求性价比的客户</div>
          </div>
          <div class="item_con">
            <div class="con_item">
              <div><span>免费</span>赠送国际顶级域名一个</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div><span>免费</span>赠送500M云虚拟主机空间</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>精美页面设计10页</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>兼容各类PC端电脑</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>兼容主流浏览器</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>开发周期<span>5-7</span>天</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
          </div>
          <div class="item_bot">
            <div
            >
              最低<span class="price">699</span> <span>元起</span>
            </div>
          </div>
        </div>
        <div class="bot_item item2">
          <div class="item_head">
            <div>区块链开发</div>
            <div>区块链钱包，NFT等开发</div>
          </div>
          <div class="item_con">
            <div class="con_item">
              <div><span>免费</span>赠送国际顶级域名一个</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div><span>免费</span>赠送500M云虚拟主机空间</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>精美页面设计10页</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>兼容各类PC端电脑</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>兼容主流浏览器</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>开发周期<span>5-7</span>天</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
          </div>
          <div class="item_bot">
            <div
            >
              最低<span class="price">1999</span> <span>元起</span>
            </div>
          </div>
        </div>
        <div class="bot_item item3">
          <div class="item_head">
            <div>定制开发</div>
            <div>定制产品，提现品牌实力和形象</div>
          </div>
          <div class="item_con">
            <div class="con_item">
              <div><span>免费</span>赠送国际顶级域名一个</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div><span>免费</span>赠送500M云虚拟主机空间</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>精美页面设计10页</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>兼容各类PC端电脑</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>兼容主流浏览器</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>开发周期<span>5-7</span>天</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
          </div>
          <div class="item_bot">
            <div
              style="color: #0c63d7"
            >
              最低<span class="price">5999</span> <span>元起</span>
            </div>
          </div>
        </div>
        <div class="bot_item item4">
          <div class="item_head">
            <div>链游开发</div>
            <div>区块链游戏，农场等开发</div>
          </div>
          <div class="item_con">
            <div class="con_item">
              <div><span>免费</span>赠送国际顶级域名一个</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div><span>免费</span>免费赠送500M云虚拟主机空间</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>精美页面设计10页</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>兼容各类PC端电脑</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>兼容主流浏览器</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>开发周期<span>5-7</span>天</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
          </div>
          <div class="item_bot">
            <div
            >
              联系客服
            </div>
          </div>
        </div>

        <div class="bot_item item5">
          <div class="item_head">
            <div>边缘计算OEM</div>
            <div>快速部署，共享边缘节点</div>
          </div>
          <div class="item_con">
            <div class="con_item">
              <div><span>免费</span>赠送国际顶级域名一个</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div><span>免费</span>免费赠送500M云虚拟主机空间</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>精美页面设计10页</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>兼容各类PC端电脑</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>兼容主流浏览器</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
            <div class="con_item">
              <div>开发周期<span>1</span>天</div>
              <div class="dh">
                <img src="../assets/indeximg/dh.png" alt="" />
              </div>
            </div>
          </div>
          <div class="item_bot">
            <div
            >
            最低5999元起
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="save_box3">
      <h1 class="box3_title">我们的优势</h1>
      <h2 class="box3_con">为什么选择我们、因为专业 !</h2>
      <div class="box3_bot">
        <div class="bot_item you1">
          <div>BRAND</div>
          <div>品牌</div>
          <div>
            兼具国际视野，创新动力 <br />
            全方位跨平台优势为一体
          </div>
        </div>
        <div class="bot_item you2">
          <div>TEAM</div>
          <div>团队</div>
          <div>
            开发队伍，产品经理<br />
            设计师，文案策划
          </div>
        </div>
        <div class="bot_item you3">
          <div>PROFESSION</div>
          <div>技术</div>
          <div>数据库开发，后台开发<br />APP开发，Web开发</div>
        </div>
        <div class="bot_item you4">
          <div>SERVER</div>
          <div>售后服务</div>
          <div>策划推广，产品运维 <br />长期售后，测试培训</div>
        </div>
      </div>
    </div>

    <div class="save_box4">
      <h1 class="box4_title">定制开发流程</h1>
      <h2 class="box4_tip">
        千里之行，始于足下，迈出网站建设第一步，从联系我们开始
      </h2>
      <div class="box4_bot">
        <div class="box_item">
          <img src="../assets/indeximg/1@2x.png" alt="" class="img1" />
          <img src="../assets/indeximg/lc1.png" alt="" class="img2" />
          <div class="tip1">项目沟通</div>
          <div class="tip2">初步需求交流，框架功能设计</div>
        </div>
        <div class="box_item">
          <img src="../assets/indeximg/2@2x.png" alt="" class="img1" />
          <img src="../assets/indeximg/lc2.png" alt="" class="img2" />
          <div class="tip1">签订合同</div>
          <div class="tip2">项目立项，确定执行团队</div>
        </div>
        <div class="box_item">
          <img src="../assets/indeximg/3@2x.png" alt="" class="img1" />
          <img src="../assets/indeximg/lc3.png" alt="" class="img2" />
          <div class="tip1">产品设计</div>
          <div class="tip2">客户确认产品原型</div>
        </div>
        <div class="box_item">
          <img src="../assets/indeximg/4@2x.png" alt="" class="img1" />
          <img src="../assets/indeximg/lc4.png" alt="" class="img2" />
          <div class="tip1">UI设计</div>
          <div class="tip2">UI高保真效果图设计</div>
        </div>
        <div class="box_item">
          <img src="../assets/indeximg/5@2x.png" alt="" class="img1" />
          <img src="../assets/indeximg/lc5.png" alt="" class="img2" />
          <div class="tip1">敏捷开发</div>
          <div class="tip2">每周迭代功能，核对开发进度</div>
        </div>
        <div class="box_item">
          <img src="../assets/indeximg/6@2x.png" alt="" class="img1" />
          <img src="../assets/indeximg/lc6.png" alt="" class="img2" />
          <div class="tip1">集成测试</div>
          <div class="tip2">整体集成测试，用户场景测试</div>
        </div>
        <div class="box_item">
          <img src="../assets/indeximg/7@2x.png" alt="" class="img1" />
          <img src="../assets/indeximg/lc7.png" alt="" class="img2" />
          <div class="tip1">部署上线</div>
          <div class="tip2">客户验收,结算尾款</div>
        </div>
        <div class="box_item">
          <img src="../assets/indeximg/8@2x.png" alt="" class="img1" />
          <img src="../assets/indeximg/lc8.png" alt="" class="img2" />
          <div class="tip1">系统维护</div>
          <div class="tip2">定期维护系统，整理客户意见</div>
        </div>
      </div>
    </div>

    <div class="save_box5">
      <div class="box5_title">您可能关注的问题</div>
      <div class="box5_tip">
        项目制作中遇到的常见问题，让您少走弯路，提高效率
      </div>
      <div class="box5_bot">
        <div class="box_item" v-for="(item, index) in list" :key="index">
          <div>{{ item.title }}</div>
          <div>{{ item.description }}</div>
        </div>
      </div>
    </div>
    <div class="box5_look" @click="to('/newsCenter')">查看更多</div>
    <mbottom />
  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
export default {
  data() {
    // this.$router.replace({ path: "X3matrix" });
    return {
      autoplay: true,
      index: 0,
      banbox: [
        {
          title: "针对性强",
          tip: "不同企业定制专属方案",
          description:
            "每一个软件开发都经过系统的分析，针对不同企业情况量身定做的，大大提高企业的工作效率。",
          img: require("../assets/indeximg/right.png"),
          l_img: require("../assets/indeximg/img/left1.png"),
          l_imgs: require("../assets/indeximg/img/left11.png"),
        },
        {
          title: "专业性强",
          tip: "强大的研发团队支持",
          description:
            "星润科技长期致力于软件开发与区块链的技术研究与服务，融合深度行业分析、创意策划实施、丰富业内资源等多项优势，依托经验丰富的专业人才队伍，更精准，跟快速的读懂客户要求。",
          img: require("../assets/indeximg/right1.jpg"),
          l_img: require("../assets/indeximg/img/left2.png"),
          l_imgs: require("../assets/indeximg/img/left22.png"),
        },
        {
          title: "对接方便",
          tip: "专业产品对接需求",
          description:
            "深度分析客户需求，快速组件专属交付小组，及时有效的满足客户需求，确保短时间内大批量交付，提供全天候现场技术服务。",
          img: require("../assets/indeximg/right2.jpg"),
          l_img: require("../assets/indeximg/img/left3.png"),
          l_imgs: require("../assets/indeximg/img/left33.png"),
        },
        {
          title: "服务周到",
          tip: "一对一贴心服务",
          description:
            "树立以客户为中心的工作作风，以客户满意为衡量一切工作的准绳。建立完善的服务网络，向客户提供专业化、标准化、多元化的服务；通过各个层面、各个阶段的定期检查及总结，及时发现工作中的不足并加以改进，实现持续改善，不断提高服务质量。向客户提供持续、高效、快捷的服务，构建优质服务品牌",
          img: require("../assets/indeximg/right3.jpg"),
          l_img: require("../assets/indeximg/img/left4.png"),
          l_imgs: require("../assets/indeximg/img/left44.png"),
        },
      ],
      problem: [],
      umobile: "",
      buy: true,
      buynum: 1,
      show: false,
      shows: false,
      shloading: false,
      sqloading: false,
      list: [
        {
          title: "网站建设周期需要多久？",
          description:
            "一般做个企业官网建设，周期为10-15个工作日，功能定制型网站，根据定制要求，核算交付周期。",
        },
        {
          title: "网站建设不满意怎么办？",
          description:
            "网站设计师按要求出设计稿，初稿不满意，可根据贵司的要求修改，并且不限制修改次数，直到满意为止！",
        },
        {
          title: "你们可以移交源代码嘛？",
          description:
            "可以的，我司在核实完您的信息之后，承诺随时提供网站建设源码和FTP信息，FTP信息很重要，请您妥善保管。",
        },
        {
          title: "外地企业怎么和你们沟通?",
          description:
            "可以通过QQ、微信、电话、视频会议等进行沟通，无需担心，不管本地及外地，项目进行中都是在线沟通的！",
        },
        {
          title: "已经有域名了，需要提供给你们什么？",
          description:
            "您只需要提供域名解析权限或管理密码就可以，我公司技术会帮您解析好IP地址，完善服务器信息！",
        },
        {
          title: "产品图片是你们帮我处理吗？",
          description:
            "我公司会帮您处理产品图（20张以内），banner图设计3-5张，如有特殊需要，请联系一诺客服！",
        },
        {
          title: "企业网站建设要多少钱？",
          description:
            "一般企业网站建设而言，如果只是展示宣传，无特殊功能，1万五千元左右，如有特殊定制，功能需求等需另行核算。",
        },
        {
          title: "你们能做网站优化推广吗？",
          description:
            "SEO是重要的引流方式，我们有多种推广方式，网站优化，竞价排名，SEM等供您选择，少花钱多办事儿！",
        },
        {
          title: "网站、APP开发有合同吗？",
          description: "签订正规网站建设合同，开具发票，诚信合作。",
        },
        {
          title: "是否有其他隐形费用?",
          description: "重合同、守承诺，网站建设期间无任何隐形费用。",
        },
      ],
    };
  },
  focus: {
    inserted: function (el, { value }) {
      console.log(el, { value });
      if (value) {
        el.focus();
      }
    },
  },

  components: {
    mtop,
    mbottom,
  },

  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    deep: true,
    immediate: true,
  },
  created() {
    this.getinfo();
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    outleft() {
      this.autoplay = true;
    },
    moveleft(val) {
      console.log(val);
      this.autoplay = false;
      this.index = val;
    },
    changeban(val) {
      console.log(val);
      this.index = val;
    },
    kefu() {
      //   console.log(1111);
      //    window._MICHAT = window._MICHAT || function () { (_MICHAT.a = _MICHAT.a || []).push(arguments) };
      // _MICHAT("accountid", 127020);
      // _MICHAT("domain", "mamkf.kanuomei.com");
      // (function (m, d, q, j, s) {
      //     j = d.createElement(q),s = d.getElementsByTagName(q)[0];
      //     j.async = true;
      //     j.charset ="UTF-8";
      //     j.src = ("https:" == document.location.protocol ? "https://" : "http://") + "mamkf.kanuomei.com/Web/JS/mivisit.js?_=t";
      //     s.parentNode.insertBefore(j, s);
      // })(window, document, "script");
    },
    toPage(row) {
      sessionStorage.setItem("conid", row.id);

      this.$router.push({
        name: "teamdetal",
        params: {
          detail: row,
        },
      });
    },
    getprice() {
      if (this.umobile == "") {
        this.$toast("请填写正确手机号!");
        return false;
      } else {
        this.$axios({
          method: "post",
          url: "/v1/apply",
          params: { mobile: this.umobile },
        })
          .then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$toast("成功提交");
              this.umobile = "";
            } else {
              this.$toast(res.data.msg);
            }
          })
          .catch((resp) => {
            this.$toast(resp.data.msg);
          });
      }
    },

    getinfo() {
      //   this.$axios({
      //     method:'get',
      //     url:'/v1/articleList',
      //     params:{type:2,cate_id:1,is_hot:1},
      // }).then((res)=>{
      //     this.list=res.data.data.list.data;
      // }).catch(resp => {
      //     console.log('请求失败');
      // });
    },
    to(e) {
      this.$router.replace({ path: e });
      // location.href = e;
    },
    tobuy() {
      location.href = "https://opensea.io/collection/cupid-love-metaverse";
    },

    close() {
      this.shows = false;
    },
  },
};
</script>
<style lang="scss" scoped>
$t-mf: "Arial";
.main {
  background: #fff;
  max-width: 100%;
  min-width: 1200px;
  width: 100%;
  .save_head {
    cursor: default;
    height: 676px;
    background: url("../assets/ximg/banner2.png");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .head_title {
      width: 676px;
      margin: 0 auto;
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: left;
    }
    .head_tip {
      width: 676px;
      margin: 12px auto;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: left;
      line-height: 35px;
    }
    .head_inp {
      width: 676px;
      margin: 0 auto;
      display: flex;
      height: 50px;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      line-height: 50px;
      .inp {
        height: 61px;
        border: none;
        width: 486px;
        padding-left: 20px;
        font-size: 22px;
        box-sizing: border-box;
      }
      .btn1 {
        width: 190px;
        height: 61px;
        background: #2b6bff;
        text-align: center;
        line-height: 61px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
      .btn1:hover {
        background: #2b6bff;
        opacity: 0.9;
      }

      .btn2:hover {
        opacity: 0.9;
      }
    }
    .head_bot {
      width: 676px;
      margin: 12px auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #e2e2e2;
      text-align: left;
      line-height: 40px;
    }
  }
  .save_box1 {
    cursor: default;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .box1_title {
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin: 0px;
      margin-top: 55px;
    }
    .box1_tip {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin: 0px;
      line-height: 50px;
    }
    .box1_bottom {
      display: flex;
      justify-content: space-between;
      margin: 35px 0;
      .bot_left {
        width: 330px;
        height: 420px;
        background: #ffffff;
        box-shadow: 0px 0px 21px 0px rgba(81, 81, 81, 0.15);
        .left_item {
          height: 104px;
          border-bottom: 1px solid #f3f3f3;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .item_right {
            width: 244px;
            div:nth-child(1) {
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              text-align: left;
            }
            div:nth-child(2) {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              text-align: left;
            }
          }
          .item_left {
            width: 42px;
            height: 42px;
            // background:#fff;
            margin-left: 20px;
            img {
              width: 42px;
              height: 42px;
            }
          }
        }
        // .left_item:hover,
        .dzact {
          background: linear-gradient(0deg, #1a8eff 0%, #16d1f1 100%);
          .item_right {
            color: #fff !important;
            div {
              color: #fff !important;
            }
          }
        }
      }
      .bot_right {
        width: 847px;
        height: 418px;
        // background: url("../assets/indeximg/right.png");
        // background-size: 100% 100%;
        box-sizing: border-box;
        // padding: 60px;
        text-align: left;
        position: relative;
        img {
          width: 847px;
          height: 418px;
          //  position:absolute;
          //  top:0px;
          //  left:0px;
        }
        // div:nth-child(1) {
        //    font-size: 18px;
        //    font-family: Microsoft YaHei;
        //    font-weight: 400;
        //    color: #ffffff;
        //   //  position:absolute;
        //   //  top:20px;
        //   //  left:20px;
        // }
        // div:nth-child(2) {
        //    width: 379px;
        //    height: 44px;
        //    font-size: 14px;
        //    font-family: Microsoft YaHei;
        //    font-weight: 400;
        //    color: #ffffff;
        //    line-height: 30px;
        //   //   position:absolute;
        //   //  top:60px;
        //   //  left:20px;
        // }

        .r_t {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          position: absolute;
          top: 60px;
          left: 90px;
        }
        .r_d {
          width: 379px;
          height: 44px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 30px;
          position: absolute;
          top: 100px;
          left: 90px;
        }
      }
    }
  }
  .save_box2 {
    cursor: default;
    // width:1920px;
    height: 626px;
    background: url("../assets/indeximg/slicebg.png");
    display: flex;
    flex-direction: column;
    justify-content: center;
    .box2_title {
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      // margin-top:35px;
    }
    .box2_tip {
      width: 1200px;
      margin: 0 auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      text-align: center;
      line-height: 50px;
    }
    .box2_bot {
      display: flex;
      width: 1600px;
      margin: 20px auto;
      justify-content: space-between;
      .bot_item {
        width: 284px;
        height: 404px;
        background: #fff;

        .item_head {
          width: 100%;
          height: 87px;
          text-align: center;
          background: linear-gradient(-88deg, #d6f6eb 0%, #ecfff9 100%);
          box-sizing: border-box;
          padding: 20px;
          color: #00af74;
          div:nth-child(1) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;

            line-height: 30px;
          }
          div:nth-child(2) {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;

            line-height: 20px;
          }
        }
        .item_con {
          box-sizing: border-box;
          padding: 20px;
          height: 245px;
          .con_item {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 30px;
            span {
              color: #00af74;
              font-size: 18px;
            }
            .dh {
              width: 12px;
              height: 17px;
              // background:url('../assets/indeximg/dh.png');
            }
          }
        }
        .item_bot {
          width: 186px;
          height: 41px;
          background: #00af74;
          border-radius: 4px;
          text-align: center;
          margin: 0 auto;
          line-height: 41px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          a {
            color: #fff;
          }
          span {
            font-size: 9px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
          }
          .price {
            font-size: 18px;
          }
        }
      }
      .bot_item:hover {
        transform: scale(1.02);
        box-shadow: 0px 0px 0.28rem 0px rgb(0 0 0 / 19%);
      }
      .item2 {
        .item_head {
          width: 100%;
          height: 87px;
          text-align: center;
          background: linear-gradient(264deg, #fee2cd 0%, #fff2e7 100%);
          box-sizing: border-box;
          padding: 20px;

          div {
            color: #8b3b34;
          }
          div:nth-child(1) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #8b3b34;
            line-height: 30px;
          }
          div:nth-child(2) {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #8b3b34;
            line-height: 20px;
          }
        }
        .item_con {
          box-sizing: border-box;
          padding: 20px;
          .con_item {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 30px;
            span {
              color: #8b3b34;
              font-size: 18px;
            }
          }
        }
        .item_bot {
          width: 186px;
          height: 41px;
          background: #8b3b34;
          border-radius: 4px;
          text-align: center;
          margin: 0 auto;
          line-height: 41px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          span {
            font-size: 10px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
          }
        }
      }

      .item3 {
        .item_head {
          width: 100%;
          height: 87px;
          text-align: center;
          background: linear-gradient(92deg, #eff8ff 0%, #d0e7f9 100%);
          box-sizing: border-box;
          padding: 20px;

          div {
            color: #0c63d7;
          }
          div:nth-child(1) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;

            line-height: 30px;
          }
          div:nth-child(2) {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 20px;
          }
        }
        .item_con {
          box-sizing: border-box;
          padding: 20px;
          .con_item {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 30px;
            span {
              color: #0c63d7;
              font-size: 18px;
            }
          }
        }
        .item_bot {
          width: 186px;
          height: 41px;
          background: #ffffff;
          border: 1px solid #0c63d7;
          color: #0c63d7;
          border-radius: 4px;
          text-align: center;
          margin: 0 auto;
          line-height: 41px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #0c63d7;
          span {
            font-size: 10px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0c63d7;
            line-height: 20px;
          }
        }
      }

      .item4 {
        .item_head {
          width: 100%;
          height: 87px;
          text-align: center;
          background: linear-gradient(264deg, #fec0ae 0%, #ffe0d7 100%);
          box-sizing: border-box;
          padding: 20px;
          color: #ff8460;
          div {
            color: #ff8460;
          }
          div:nth-child(1) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #8b3b34;
            line-height: 30px;
          }
          div:nth-child(2) {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #8b3b34;
            line-height: 20px;
          }
        }
        .item_con {
          box-sizing: border-box;
          padding: 20px;
          .con_item {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 30px;
            span {
              font-size: 18px;
              color: #ff8460;
            }
          }
        }
        .item_bot {
          width: 186px;
          height: 41px;
          background: #ff8460;
          border-radius: 4px;
          text-align: center;
          margin: 0 auto;
          line-height: 41px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          a {
            color: #ffff;
            span {
              font-size: 10px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 20px;
            }
          }
        }
      }

      .item5 {
        .item_head {
          width: 100%;
          height: 87px;
          text-align: center;
          background: linear-gradient(264deg, #aefed7 0%, #f3f3f3  100%);
          box-sizing: border-box;
          padding: 20px;
          color: #ff8460;
          div {
            color: #6ba236;
          }
          div:nth-child(1) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #6ba236;
            line-height: 30px;
          }
          div:nth-child(2) {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #6ba236;
            line-height: 20px;
          }
        }
        .item_con {
          box-sizing: border-box;
          padding: 20px;
          .con_item {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 30px;
            span {
              font-size: 18px;
              color: #6ba236;
            }
          }
        }
        .item_bot {
          width: 186px;
          height: 41px;
          background: #90e4bb;
          border-radius: 4px;
          text-align: center;
          margin: 0 auto;
          line-height: 41px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          a {
            color: #ffff;
            span {
              font-size: 10px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .save_box3 {
    cursor: default;
    width: 100%;
    height: 578px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .box3_title {
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      width: 1200px;
      margin: 0 auto;
      text-align: center;
    }
    .box3_con {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      line-height: 50px;
    }
    .box3_bot {
      width: 1200px;
      margin: 40px auto;
      padding: 0 30px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .bot_item {
        width: 234px;
        height: 300px;
        background: #ffffff;
        box-shadow: 0px 0px 21px 0px rgba(81, 81, 81, 0.19);
        border-radius: 4px;
        border-bottom: 3px solid #0fb3b4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0 30px;
        div:nth-child(1) {
          font-size: 26px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #0fb3b4;
          line-height: 30px;
        }
        div:nth-child(2) {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
          margin-top: 40px;
          text-align: center;
        }
        div:nth-child(3) {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #7e8184;
          line-height: 30px;
          text-align: left;
          margin-top: 27px;
          text-align: center;
        }
      }
      .you1 {
        border-bottom: 3px solid #0fb3b4;
        div:nth-child(1) {
          font-size: 26px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #0fb3b4;
          line-height: 30px;
        }
      }
      .you2 {
        border-bottom: 3px solid #8b3b34;
        div:nth-child(1) {
          color: #8b3b34;
        }
      }
      .you3 {
        border-bottom: 3px solid #16d1f1;
        div:nth-child(1) {
          color: #16d1f1;
        }
      }
      .you4 {
        border-bottom: 3px solid #ff8460;
        div:nth-child(1) {
          color: #ff8460;
        }
      }
      .bot_item:hover {
        color: #fff !important;
        background: linear-gradient(0deg, #1a8eff 0%, #19d5f5 100%);
        box-shadow: 0px 0px 21px 0px rgba(26, 150, 254, 0.19);
        border-radius: 10px;
        transform: scale(1.1);
        div {
          color: #fff !important;
        }
      }
    }
  }
  .save_box4 {
    height: 680px;
    background: url("../assets/indeximg/slicebg.png");
    padding: 1px;
    .box4_title {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-top: 54px;
    }
    .box4_tip {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-top: 15px;
    }
    .box4_bot {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 1200px;
      margin: 0 auto;
      margin-top: 25px;
      .box_item {
        width: 284px;
        height: 220px;
        background: #ffffff;
        border: 1px solid #e4e4e4;
        opacity: 0.88;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        padding: 10px;
        box-sizing: border-box;
        .img1 {
          display: flex;
          justify-content: left;
          width: 36px;
          height: 46px;
        }
        .img2 {
          display: flex;
          justify-content: center;
          margin: 0 auto;
          width: 56px;
          height: 47px;
        }
        .tip1 {
          margin-top: 31px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        .tip2 {
          // margin-top:20px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 35px;
        }
      }

      .box_item:hover {
        transform: scale(1.02);
        box-shadow: 0px 0px 0.28rem 0px rgb(0 0 0 / 19%);
      }
    }
  }
  .save_box5 {
    // max-height:520px;
    min-height: 300px;
    background: #fff;
    .box5_title {
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #020202;
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      margin-top: 55px;
    }
    .box5_tip {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #020202;
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      margin-top: 13px;
    }
  }
  .box5_look {
    width: 100px;
    height: 34px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 17px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 34px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 35px;
    // margin-top:-38px;
  }
  .box5_look:hover {
    background: linear-gradient(0deg, #1a8eff 0%, #16d1f1 100%);
    color: #fff;
  }
  .box5_bot {
    width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .box_item {
      width: 590px;
      height: 95px;
      background: #ffffff;
      border: 1px solid #e9e9e9;
      margin-bottom: 20px;
      box-sizing: border-box;
      padding: 15px;
      text-align: left;
      div:nth-child(1) {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      div:nth-child(2) {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
        margin-top: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .box_item:hover {
      background: linear-gradient(0deg, #eee 0%, #fff 100%);
    }
  }
}
.h5main {
  min-width: 100%;
  .save_head {
    width: 100%;

    height: 700px;
    .head_title {
      width: calc(100% - 40px);
      margin: 10px 0;
      font-size: 32px;
    }
    .head_tip {
      width: calc(100% - 40px);
      margin: 0;
      font-size: 24px;
    }
    .head_inp {
      width: calc(100% - 40px);
      margin: 20px auto;
      height: auto;
      .inp {
        width: auto;
        height: 68px;
        flex: 1;
      }
      .btn1 {
        width: auto;
        padding: 0px 20px;
        height: 68px;
        line-height: 68px;
      }
    }
    .head_bot {
      width: calc(100% - 40px);
      margin: 0;
    }
  }
  .save_box1 {
    width: 100%;
    .box1_title {
      font-size: 32px;
    }
    .box1_tip {
      font-size: 26px;
    }
    .box1_bottom {
      flex-direction: column;
      .bot_left {
        width: 100%;
        flex-direction: row;
        display: flex;
        align-items: center;
        height: auto;
        flex-wrap: wrap;
        .left_item {
          width: calc(50% - 14px);
          border: 1px solid #f3f3f3;
          margin: 5px;
          justify-content: flex-start;
          .item_right {
            margin-left: 20px;
            div:nth-child(1) {
              font-size: 26px;
            }
            div:nth-child(2) {
              font-size: 24px;
            }
          }
        }
      }
      .bot_right {
        width: 100%;
        .r_t {
          font-size: 28px;
        }
        .r_d {
          font-size: 24px;
          width: calc(80% - 40px);
          height: auto;
          line-height: 32px;
          margin-top: 20px;
        }
      }
    }
  }
  .save_box2 {
    width: 100%;
    height: auto;
    padding: 90px 0;
    position: relative;
    z-index: 99;
    .box2_title {
      width: 100%;
      font-size: 32px;
    }
    .box2_tip {
      width: 100%;
      font-size: 26px;
    }
    .box2_bot {
      width: 100%;
      flex-wrap: wrap;
      .bot_item {
        width: calc(100% - 60px);
        margin: 10px auto;
        height: auto;
        .item_head {
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          div:nth-child(1) {
            font-size: 32px;
          }
          div:nth-child(2) {
            font-size: 24px;
            margin-top: 20px;
            white-space: nowrap;
          }
        }
        .item_con {
          height: auto;
          .con_item {
            font-size: 26px;
            line-height: 52px;
          }
        }
        .item_bot {
          width: 250px;
          height: 68px;
          font-size: 26px;
          line-height: 68px;
          margin: 20px auto 50px;
        }
      }
    }
  }
  .save_box3 {
    width: 100%;
    height: auto;
    padding: 90px 0;
    .box3_title {
      width: 100%;
      font-size: 32px;
    }
    .box3_con {
      width: 100%;
      font-size: 26px;
    }
    .box3_bot {
      flex-wrap: wrap;
      width: 100%;
      .bot_item {
        width: calc(50% - 40px);
        margin: 20px;
        padding: 0;
        div:nth-child(1) {
          font-size: 28px;
        }
        div:nth-child(2) {
          font-size: 26px;
        }
        div:nth-child(3) {
          font-size: 24px;
        }
      }
    }
  }
  .save_box4 {
    width: 100%;
    height: auto;
    padding: 90px 0;
    .box4_title {
      width: 100%;
      font-size: 32px;
    }
    .box4_tip {
      width: 100%;
      font-size: 26px;
    }
    .box4_bot {
      flex-wrap: wrap;
      width: 100%;
      .box_item {
        width: calc(50% - 40px);
        margin: 20px;
        .tip1 {
          font-size: 26px;
        }
        .tip2 {
          font-size: 20px;
        }
      }
    }
  }
  .save_box5 {
    width: 100%;
    height: auto;
    padding: 90px 0;
    .box5_title {
      width: 100%;
      font-size: 32px;
    }
    .box5_tip {
      width: 100%;
      font-size: 26px;
    }
    .box5_bot {
      flex-wrap: wrap;
      width: 100%;
      .box_item {
        width: calc(100% - 40px);
        margin: 20px;
        height: auto;
        div:nth-child(1) {
          font-size: 28px;
        }
        div:nth-child(2) {
          font-size: 26px;
          line-height: 32px;
        }
      }
    }
  }
  .box5_look {
    width: 200px;
    height: 68px;
    line-height: 68px;
    border-radius: 68px;
  }
}
</style>